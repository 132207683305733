import { createTheme } from "@mui/material";

const BonsaiHealth = createTheme({
  /******************************************
   * TYPOGRAPHY
   *****************************************/
  typography: {
    fontFamily: "Biotif-Regular",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#33313A",
    h6: {
      fontWeight: 500,
      fontSize: "0.75rem",
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      fontFamily: "Biotif-Medium",
    },
    h2: {
      fontSize: "1.75rem",
      fontFamily: "Biotif-Medium",
    },
    h1: {
      fontSize: "2.125rem",
      fontWeight: 700,
    },
    body: {
      fontFamily: "Open Sans",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#33313A",
    },
    bodyTimes: {
      fontFamily: "Times New Roman",
      fontSize: "1rem",
      lineHeight: 1.25,
    },
    bodyArial: {
      fontFamily: "Arial, Helvetica",
      fontSize: "1rem",
      lineHeight: 1.25,
    },
    bodyBiotif: {
      fontFamily: "Biotif-Regular",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#33313A",
    },
    bodyBiotifMedium: {
      fontFamily: "Biotif-Medium",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.25,
      color: "#262039",
    },
    bodyBiotifBold: {
      fontFamily: "Biotif-Bold",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: 1.5,
      color: "#262039",
    },
    bodySmall: {
      fontFamily: "Open Sans",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.25,
      color: "#33313A",
    },
    bodyLight: {
      fontFamily: "Inter",
      fontSize: "1.125rem",
      fontWeight: 300,
      lineHeight: 1.2,
      color: "#33313A",
    },
    bodySemi: {
      fontFamily: "Inter",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    bodySemi2: {
      fontFamily: "Inter",
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    bodyBold: {
      fontFamily: "Inter",
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: 1.5,
      color: "#33313A",
    },
    formLabel: {
      fontFamily: "Inter",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1,
      color: "#333333",
    },
  },
  /******************************************
   * COLORS
   *****************************************/
  palette: {
    primary: {
      light: "#7D7A96",
      lighter: "#eaeaef",
      main: "#6340C1",
      dark: "#33313A",
      contrastText: "#fff",
      gray: "#04213C",
      gray50: "#F6F4FA",
      gray200: "#E7E3EF",
      gray500: "#706986",
      gray600: "#514B65",
      danger: "#FD8D77",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    success: {
      main: "#17B26A",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1312,
      xl: 1780,
    },
  },
});

export default BonsaiHealth;
