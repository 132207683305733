import { Box } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import imgBannerOne from "../images/banner_1.webp";
import imgBannerOneXl from "../images/banner_1_xl.webp";
import imgBannerTwo from "../images/banner_2.webp";
import imgBannerTwoXl from "../images/banner_2_xl.webp";

const DesktopBanners = () => {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex", xl: "none" }, justifyContent: "flex-end" }}>
        <motion.div
          initial={{
            opacity: 0,
            position: "relative",
            top: 400,
            width: 300,
            height: 1232,
            right: 40,
          }}
          animate={{
            top: -108,
            opacity: 1,
          }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box
            component="img"
            src={imgBannerOne}
            alt="banner one"
            width="300"
            height="1232"
            sx={{ width: 300, height: 1232 }}
          />
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
            position: "relative",
            top: -744,
            width: 300,
            height: 1232,
            right: 20,
          }}
          animate={{
            top: -150,
            opacity: 1,
          }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box
            component="img"
            src={imgBannerTwo}
            alt="banner one"
            width="300"
            height="1232"
            sx={{ width: 300, height: 1232 }}
          />
        </motion.div>
      </Box>

      <Box sx={{ display: { xs: "none", xl: "flex" }, justifyContent: "flex-end" }}>
        <motion.div
          initial={{
            opacity: 0,
            position: "relative",
            width: 413,
            height: 1232,
            right: 40,
            top: 600,
          }}
          animate={{
            top: -150,
            opacity: 1,
          }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box
            component="img"
            src={imgBannerOneXl}
            alt="banner one"
            width="413"
            height="1695"
            sx={{ width: 413, height: 1695 }}
          />
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
            position: "relative",
            width: 413,
            height: 1695,
            top: -900,
            right: 20,
          }}
          animate={{
            top: -250,
            opacity: 1,
          }}
          transition={{ duration: 1.75, ease: "easeInOut", delay: 2 }}
        >
          <Box
            component="img"
            src={imgBannerTwoXl}
            alt="banner two"
            width="413"
            height="1695"
            sx={{ width: 413, height: 1695 }}
          />
        </motion.div>
      </Box>
    </>
  );
};

export default DesktopBanners;
