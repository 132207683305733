import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import BonsaiHealth from "../themes/BonsaiHealth";
import { Outlet } from "react-router-dom";
import "../App.css";

const MainLayout = () => {
  return (
    <ThemeProvider theme={BonsaiHealth}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
};

export default MainLayout;
