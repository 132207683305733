import { Box } from "@mui/material";
import React from "react";
import imgHeadline from "../images/aesthetic_insights.svg";
import imgHeadlineXL from "../images/headline_xl.svg";
import imgCursor from "../images/cursor.svg";
import imgCursorXL from "../images/cursor_xl.svg";
import imgTagline from "../images/tagline.svg";
import imgTaglineXL from "../images/tagline_xl.svg";
import { motion } from "framer-motion";

const DesktopSplash = () => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: 466,
          height: 243,
          top: 268,
          left: 96,
          display: { xs: "none", md: "block", xl: "none" },
        }}
      >
        <Box component="img" src={imgHeadline} alt="headline" sx={{ position: "relative", zIndex: 1 }} />
        <motion.div
          initial={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "189px",
            height: "96px",
            zIndex: 3,
          }}
          animate={{
            x: [0, 480, 0, 400],
            y: [0, 0, 122, 122],
          }}
          transition={{ duration: 3, delay: 0.51 }}
        >
          <Box
            component="img"
            src={imgCursor}
            width="189"
            height="96"
            alt="cursor"
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        </motion.div>
        <motion.div
          initial={{
            width: "466px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "122px",
            zIndex: "2",
            top: 0,
            right: 0,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 0.97, delay: 0.495 }}
        />
        <motion.div
          initial={{
            width: "400px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "130px",
            zIndex: "2",
            bottom: 0,
            right: 66,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 1, delay: 2.5 }}
        />
        <motion.div
          initial={{
            opacity: 0,
            position: "absolute",
            left: -10,
            top: 278,
          }}
          animate={{
            opacity: 1,
            left: 0,
          }}
          transition={{ duration: 1, delay: 3, ease: "easeInOut" }}
        >
          <Box component="img" src={imgTagline} alt="headline" />
        </motion.div>
      </Box>

      <Box
        sx={{
          position: "absolute",
          width: 635,
          height: 331,
          top: 384,
          left: 96,
          display: { xs: "none", xl: "block" },
        }}
      >
        <Box component="img" src={imgHeadlineXL} alt="tagline" sx={{ position: "relative", zIndex: 1 }} />
        <motion.div
          initial={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "189px",
            height: "96px",
            zIndex: 3,
          }}
          animate={{
            x: [0, 635, 0, 550],
            y: [0, 0, 165, 165],
          }}
          transition={{ duration: 3, delay: 0.50 }}
        >
          <Box
            component="img"
            src={imgCursorXL}
            width="261"
            height="133"
            alt="cursor"
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        </motion.div>
        <motion.div
          initial={{
            width: "635px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "165px",
            zIndex: "2",
            top: 0,
            right: 0,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 0.99, delay: 0.495 }}
        />
        <motion.div
          initial={{
            width: "540px",
            backgroundColor: "#F6F4FA",
            position: "absolute",
            height: "170px",
            zIndex: "2",
            bottom: 0,
            right: 96,
          }}
          animate={{
            width: 0,
          }}
          transition={{ duration: 0.97, delay: 2.5 }}
        />
        <motion.div
          initial={{
            opacity: 0,
            position: "absolute",
            left: -10,
            top: 380,
          }}
          animate={{
            opacity: 1,
            left: 0,
          }}
          transition={{ duration: 1, delay: 3, ease: "easeInOut" }}
        >
          <Box component="img" src={imgTaglineXL} alt="headline" />
        </motion.div>
      </Box>
    </>
  );
};

export default DesktopSplash;
