import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import imgLogo from "../images/logo.svg";
import { Box } from "@mui/material";

const TopLogo = () => {
  return (
    <motion.div
      initial={{ left: "-20px", position: "relative", opacity: 0 }}
      animate={{ left: 0, opacity: 1 }}
      transition={{ duration: 0.25, ease: "easeInOut" }}
    >
      <Link to="/">
        <Box component="img" src={imgLogo} alt="logo" sx={{ position: "relative", height: { xs: 24, md: 32 } }} />
      </Link>
    </motion.div>
  );
};

export default TopLogo;
