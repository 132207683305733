import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import MobileSplash from "../components/MobileSplash";
import DesktopSplash from "../components/DesktopSplash";
import imgLogo from "../images/logo.svg";
import DesktopBanners from "../components/DesktopBanners";

const HomePage = () => {
  return (
    <>
      <motion.div
        initial={{ top: "-50px", position: "absolute", opacity: 0, width: "100%", zIndex: 10 }}
        animate={{ top: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 4 }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            top: 0,
            backgroundColor: "#7352D5",
            padding: "4px 10px",
          }}
        >
          <Container sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} maxWidth="xl">
            <Typography sx={{ color: "white" }}>Contact:&nbsp;</Typography>
            <Link href="mailto:sales@bonsaihealth.com" target="_blank" underline="hover" sx={{ color: "white" }}>
              <Typography>sales@bonsaihealth.com</Typography>
            </Link>
          </Container>
        </Box>
      </motion.div>
      <Box
        sx={{
          oveflow: "hidden",
          height: "100%",
          position: "relative",
          maxWidth: 1920,
          minHeight: 900,
          marginX: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: 800 },
            minWidth: { xs: "100%", md: 800 },
            padding: { xs: "46px 0 0 16px", md: "56px 0 0 64px" },
          }}
        >
          <motion.div
            initial={{ left: "-20px", position: "relative", opacity: 0 }}
            animate={{ left: 0, opacity: 1 }}
            transition={{ duration: 0.25, ease: "easeInOut" }}
          >
            <Box component="img" src={imgLogo} alt="logo" sx={{ position: "relative", height: { xs: 24, md: 32 } }} />
          </motion.div>
          <MobileSplash />
          <DesktopSplash />
        </Box>
        <Box sx={{ position: "relative", width: "100%" }}>
          <DesktopBanners />
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
