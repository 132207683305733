import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import TopLogo from "../components/TopLogo";

const TermsOfUse = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Use | Bonsai</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container sx={{ p: 3 }}>
        <TopLogo />
        <Box pt={3}>
          <Typography component="p" variant="bodyTimes" sx={{ mb: 2, textAlign: "center" }}>
            <strong>TERMS OF USE AGREEMENT</strong>
          </Typography>
          <Typography component="p" variant="bodyTimes" sx={{ mb: 2 }}>
            This Terms of Use Agreement constitutes a binding agreement (the <strong>“Agreement”</strong>) between
            Bonsai Health, Inc. (<strong>“Bonsai Health”</strong>), and the legal entity you represent (referred to
            herein as <strong>“You”</strong> or <strong>“Your”</strong>). The <strong>“Effective Date”</strong> of this
            Agreement shall be the earlier of (i) Your acceptance of the terms of this Agreement by clicking on the “I
            Accept” button, or (ii) Your use of the Services (as define below). Each party will be referred to herein as
            a <strong>“Party”</strong> and collectively, the <strong>“Parties.”</strong>
          </Typography>
          <Typography component="p" variant="bodyTimes" sx={{ mb: 2 }}>
            This Agreement includes and incorporates by reference all Exhibits hereto, including, to the extent
            applicable, the Business Associate Agreement (<strong>“BAA”</strong>) incorporated herein, and any
            amendments to the Agreement and any Order Forms (as defined below) to which the Parties may agree in writing
            from time to time.
          </Typography>
          <Typography component="p" variant="bodyTimes" sx={{ mb: 2 }}>
            PLEASE READ THIS AGREEMENT CAREFULLY. THIS AGREEMENT GOVERNS YOUR USE OF THE SERVICES. BY CLICKING ON THE “I
            ACCEPT” BUTTON OR ACCESSING OR USING THE SERVICES, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND
            AGREE TO BE BOUND BY THIS AGREEMENT, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH BONSAI HEALTH,
            AND (3) YOU HAVE THE AUTHORITY TO ENTER INTO THE AGREEMENT ON BEHALF OF THE ENTITY YOU HAVE NAMED AS THE
            USER, AND TO BIND THAT ENTITY TO THE AGREEMENT.{" "}
            <strong>IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, YOU MAY NOT ACCESS OR USE THE SERVICES.</strong>
          </Typography>
          <Typography component="p" variant="bodyTimes" sx={{ mb: 2, textAlign: "center" }}>
            <strong>AGREEMENT</strong>
          </Typography>
          <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
            <li>
              <span>1.</span>
              <strong>DEFINITIONS.</strong> Capitalized terms have the meaning set forth below or as defined within this
              Agreement.
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>1.1</span>“<strong>API(s)</strong>” means the application programming interfaces provided by
                  Bonsai Health to facilitate the integration with Third-Party Services.
                </li>
                <li>
                  <span>1.2</span>“<strong>Authorized User</strong>” means Your employees, agents, and independent
                  contractors who are authorized to access the Services pursuant to Your rights under this Agreement.
                </li>
                <li>
                  <span>1.3</span>“<strong>Documentation</strong>” means all specifications, user manuals, and other
                  technical materials relating to the Services that are provided or made available to You by Bonsai
                  Health, and as may be modified by Bonsai Health from time to time.
                </li>
                <li>
                  <span>1.4</span>“<strong>Fees</strong>” means any fees incurred and set forth on an Order Form.
                </li>
                <li>
                  <span>1.5</span>“<strong>Intellectual Property Rights</strong>” means all past, present, and future
                  rights of the following types, which may exist or be created under the laws of any jurisdiction in the
                  world: (a) rights associated with works of authorship, including exclusive exploitation rights,
                  copyrights, moral rights, and mask work rights; (b) trademark and trade name rights and similar
                  rights; (c) trade secret rights; (d) patent and industrial property rights; (e) other proprietary
                  rights of every kind and nature; and (f) rights in or relating to registrations, renewals, extensions,
                  combinations, divisions, and reissues of, and applications for, any of the rights referred to in
                  clauses (a) through (e) of this sentence.
                </li>
                <li>
                  <span>1.6</span>“<strong>Order Form(s)</strong>” means any ordering document, confirmation email, or
                  other written acknowledgement of an order placed for access to the Platform provided by Bonsai Health
                  and referring to this Agreement.
                </li>
                <li>
                  <span>1.7</span>“<strong>Performance Data</strong>” means general performance and usage data generated
                  or collected through or in connection with Your use of the Services (such as technical logs, account
                  and login data, and processed volumes).
                </li>
                <li>
                  <span>1.8</span>“<strong>Platform</strong>” means Bonsai Health’s software-as-a-service platform (as
                  such platform may be modified and updated over time) that makes available to You certain data
                  analytics and insights on end customer engagement.
                </li>
                <li>
                  <span>1.9</span>“<strong>Services</strong>” means the Website, the Platform, APIs, Documentation,
                  content and information contained within the foregoing, other services identified on the Order Form by
                  Bonsai Health, and improvements or enhancements made to any of the foregoing, in each case solely to
                  the extent that Bonsai Health elects.
                </li>
                <li>
                  <span>1.10</span>“<strong>Website</strong>” means www.bonsaihealth.com and all of its subdomains.
                </li>
                <li>
                  <span>1.11</span>“<strong>Your Data</strong>” means all content, data, and information provided or
                  submitted by You, or on behalf of, You or Your Authorized Users, in connection with the Services. Your
                  Data does not include Performance Data or Derived Data.
                </li>
              </Typography>
            </li>
            <li>
              <span>2.</span>
              <strong>ACCESS TO THE SERVICES; RESTRICTIONS.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>2.1</span>
                  <strong>Access.</strong> Subject to the terms and conditions of this Agreement, Bonsai Health hereby
                  grants to You, and the Authorized Users designated by You, a limited, non-exclusive, non-transferable
                  (except as permitted under Section 10.3), non-sublicensable right during the Term to: (a) use and
                  access the Platform and the APIs in accordance with the Documentation and the terms of this Agreement
                  and Order Forms, including any usage limitations identified therein; and (b) use and make reasonable
                  copies of the Documentation, in each case solely for Your internal business purposes. You acknowledge
                  and agree that Bonsai Health may update the Services from time to time with or without notifying You,
                  provided that Bonsai Health shall use commercially reasonable efforts to ensure that any such updates
                  do not materially degrade the functionality of the Services.
                </li>
                <li>
                  <span>2.2</span>
                  <strong>Restrictions.</strong> You shall not, and shall procure that Your Authorized Users shall not:
                  (a) allow any third party to access the Services except as expressly allowed herein; (b) modify,
                  adapt, alter or translate the Services; (c) sublicense, lease, sell, resell, rent, loan, distribute,
                  transfer or otherwise allow the use of the Services for the benefit of any unauthorized third party;
                  (d) reverse engineer, decompile, disassemble, or otherwise derive or determine or attempt to derive or
                  determine the source code (or the underlying ideas, algorithms, structure or organization) of the
                  Services, except as permitted by law; (e) interfere in any manner with the operation of the Services
                  or the hardware and network used to operate the same, or attempt to probe, scan or test vulnerability
                  of the Services without prior authorization of Bonsai Health; (f) modify, copy or make derivative
                  works based on any part of the Services; (g) access or use the Services to build a similar or
                  competitive product or service or otherwise engage in competitive analysis or benchmarking; (h)
                  attempt to access the Services through any unapproved interface; (i) use the Services in connection
                  with any of Your time-critical or mission-critical functions; (j) use the API(s) in a manner that, in
                  Bonsai Health’s discretion, exceeds specified call/query volumes, constitutes abusive or excessive
                  use, or otherwise fails to comply with or is inconsistent with this Agreement; (k) remove, alter, or
                  obscure any proprietary notices (including copyright and trademark notices) of Bonsai Health or its
                  licensors on the Services or any copies thereof; or (l) otherwise use the Services in any manner that
                  exceeds the scope of use permitted under Section 2.1 or in a manner inconsistent with applicable law,
                  the Documentation, the Order Form, or this Agreement. Bonsai Health reserves the right to suspend
                  Yours or any Authorized User’s access to the Services for any failure, or suspected failure, to comply
                  with the foregoing conditions.
                </li>
                <li>
                  <span>2.3</span>
                  <strong>Security.</strong> You acknowledge and agree that: (a) You are responsible for maintaining the
                  confidentiality of all Your passwords and are solely responsible for all activities that occur under
                  the account associated with the email You used the register for the Platform; and (b) You will notify
                  Bonsai Health promptly of any actual or suspected unauthorized use of any account, username, or
                  passwords, or any other breach or suspected breach of this Agreement. Bonsai Health reserves the right
                  to suspend, disable, or terminate Your access to the Services that Bonsai Health reasonably determines
                  may have been used by an unauthorized third party.
                </li>
                <li>
                  <span>2.4</span>
                  <strong>Responsible Use of the Services.</strong> You shall not provide to Bonsai Health or otherwise
                  make available (including upload) any of Your Data to the Services that: (a) infringes or
                  misappropriate any third party’s Intellectual Property Rights or other proprietary rights; (b) is
                  inaccurate, deceptive, discriminatory, biased, unethical, defamatory, obscene, pornographic, or
                  illegal; (c) constitutes “personal data,” “personal information,” or similarly defined term under any
                  U.S. state comprehensive consumer privacy laws (such as the California Consumer Privacy Act) to the
                  extent You are subject to such laws as a “business,” “controller” or similarly defined term under such
                  applicable laws; (d) constitutes “protected health information” under the Health Insurance Portability
                  and Accountability Act and its implementing regulations, each as amended (“<strong>HIPAA</strong>”)
                  unless You first inform Bonsai Health in accordance with Section 5.5; or (d) contains any viruses,
                  worms, or other malicious computer programming codes that may damage the Services. You agree that any
                  use of the Services contrary to the responsible uses described herein constitutes unauthorized and
                  improper use of the Services.
                </li>
              </Typography>
            </li>
            <li>
              <span>3.</span>
              <strong>FEES, PAYMENT, AND TAXES.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>3.1</span>
                  <strong>Fees; Invoicing and Payment.</strong> You are responsible for paying all Fees, including,
                  without limitation, any monthly, annual, or variable amounts based on usage (e.g., the number of
                  patients per location), associated with Your account in accordance with the payment terms set forth in
                  the Order Form. Unless otherwise set forth on the Order Form, any variable amounts charged based on
                  usage will be billed monthly in arrears. You will be billed for all amounts due under this Agreement
                  via the payment method on file. You must provide current, complete, and accurate information for
                  billing and promptly update all information to keep such information current, complete, and accurate.
                  If there are no valid payment methods on file for You, Bonsai Health may send You invoices for the
                  balance of any amounts due. All Fees are quoted in United States Dollars and, except as set forth
                  otherwise in this Agreement, are non-refundable. At the end of each year during the Term, Bonsai
                  Health reserves the right to modify any pricing or payment terms, including, without limitation,
                  increasing the pricing associated with any Fees payable, for the forthcoming year upon written notice
                  to You at least thirty (30) days prior to the commencement of the forthcoming year.
                </li>
                <li>
                  <span>3.2</span>
                  <strong>Late Payments.</strong> Payments that are past due will be subject to interest at the rate of
                  one and one-half percent (1.5%) per month (or, if less, the maximum allowed by applicable law) on that
                  overdue balance. You will be responsible for any costs resulting from collection by Bonsai Health of
                  any such overdue balance, including, without limitation, reasonable attorneys’ fees and court costs.
                  Bonsai Health reserves the right (in addition to any other rights or remedies Bonsai Health may have)
                  to suspend You and all Authorized Users’ access to the Services if any Fees are more than fifteen (15)
                  days overdue until such amounts are paid in full.
                </li>
                <li>
                  <span>3.3</span>
                  <strong>Taxes; Withholding.</strong> The Fees do not include taxes, duties, or charges of any kind. If
                  Bonsai Health is required to pay or collect any local, value added, goods and services taxes, or any
                  other similar taxes or duties arising out of or related to this Agreement (not including taxes based
                  on Bonsai Health’s income), then such taxes and/or duties shall be billed to and paid by You. If any
                  applicable law requires You to withhold amounts from any payments to Bonsai Health hereunder, then the
                  sum payable by You upon which the deduction or withholding is based will be increased to the extent
                  necessary to ensure that, after such deduction or withholding, Bonsai Health receives and retains,
                  free from liability for such deduction or withholding, a net amount equal to the amount Bonsai Health
                  would have received and retained in the absence of such required deduction or withholding.
                </li>
              </Typography>
            </li>
            <li>
              <span>4.</span>
              <strong>TERM AND TERMINATION.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>4.1</span>
                  <strong>Term.</strong> This Agreement will begin on the Effective Date and will continue in full force
                  and effect for as long as any Order Form remains in effect, unless earlier terminated in accordance
                  with the Agreement (the “<strong>Term</strong>”).
                </li>
                <li>
                  <span>4.2</span>
                  <strong>Order Form Term.</strong> Each Order Form shall have an initial subscription term specified on
                  the Order Form. Thereafter, the Order Form will automatically renew for additional terms of one (1)
                  year unless either Party gives written notice of non-renewal to the other Party at least thirty (30)
                  days prior to the expiration of the then-current term.
                </li>
                <li>
                  <span>4.3</span>
                  <strong>Termination for Breach.</strong> Either Party may terminate this Agreement immediately upon
                  notice to the other Party if: (a) the other Party materially breaches this Agreement, and such breach
                  remains uncured more than thirty (30) days after receipt of written notice of such breach; or (b) the
                  other Party: (i) becomes insolvent; (ii) files a petition in bankruptcy that is not dismissed within
                  sixty (60) days of commencement; or (iii) makes an assignment for the benefit of its creditors.
                </li>
                <li>
                  <span>4.4</span>
                  <strong>Effect of Termination.</strong> Upon the earlier of expiration or termination of this
                  Agreement: (a) except as needed to perform its obligations or exercise its rights herein, each Party
                  shall immediately return or, if requested by a Party, destroy all (including any copies of)
                  Confidential Information of the other Party and, upon request, each Party shall provide written
                  certification that the foregoing obligations have been completed; (b) the rights and licenses granted
                  to You hereunder will immediately terminate, You will cease use of the Services and Documentation, and
                  return or destroy all copies of the Documentation in its possession/control; (c) all definitions and
                  the Parties’ rights and obligations under Sections 2.2 - 2.4, 3, 4.4, and 5 - 10 will survive
                  termination of this Agreement and/or any Order Form; and (d) termination of this Agreement will not
                  limit either Party from pursuing any other remedies available to it, including injunctive relief, nor
                  will termination relieve You of Your obligation to pay all Fees that accrued prior to such
                  termination.
                </li>
              </Typography>
            </li>
            <li>
              <span>5.</span>
              <strong>CONFIDENTIALITY.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>5.1</span>
                  <strong>Confidential Information.</strong> Each Party (“<strong>Receiving Party</strong>”)
                  acknowledges that it may receive from the other Party (“<strong>Disclosing Party</strong>”)
                  confidential information relating to the Disclosing Party and such confidential information includes,
                  but is not limited to, technical, business, marketing and financial information, and any other
                  information that could reasonably be considered confidential or proprietary (“
                  <strong>Confidential Information</strong>”). The terms of this Agreement and any Order Form, the
                  non-public features of the Services, and all technical information relating thereto shall be
                  considered Confidential Information of Bonsai Health. All Confidential Information disclosed by
                  Disclosing Party shall remain the property of the Disclosing Party. The Disclosing Party reserves all
                  rights in its Confidential Information. Nothing in this Agreement or the disclosures envisaged by this
                  Agreement shall (except for the limited use right above) operate to transfer, or operate as a grant of
                  any, Intellectual Property Rights in the Confidential Information.
                </li>
                <li>
                  <span>5.2</span>
                  <strong>Exclusions.</strong> Confidential Information does not include information that: (a) is or
                  becomes generally available to the public other than through a wrongful act of the Receiving Party;
                  (b) is or becomes available to the Receiving Party on a non-confidential basis from a source that is
                  entitled to disclose it to the Receiving Party; or (c) is independently developed by the Receiving
                  Party, its employees or third-party contractors without access to or use of the Disclosing Party’s
                  Confidential Information.
                </li>
                <li>
                  <span>5.3</span>
                  <strong>Obligations.</strong> During and after the Term, the Receiving Party shall: (a) not use or
                  disclose Confidential Information of the Disclosing Party without the prior written consent of the
                  Disclosing Party except as required for the performance of, or the exercise of rights under, this
                  Agreement; and (b) take no less than the same measures that it takes with its own Confidential
                  Information, and in any case no less than reasonable measures, to maintain the Confidential
                  Information of the Disclosing Party.
                </li>
                <li>
                  <span>5.4</span>
                  <strong>Disclosure by Law.</strong> Either Party may disclose Confidential Information to the extent
                  required by law, provided that the Receiving Party gives the Disclosing Party reasonable advance
                  notice of such required disclosure and cooperates with the Disclosing Party so that the Disclosing
                  Party has the opportunity to obtain appropriate confidential treatment for such Confidential
                  Information.
                </li>
                <li>
                  <span>5.5</span>
                  <strong>
                    Protected Health Information. You agree that Your Data will not include any protected health
                    information subject to HIPAA without first informing Bonsai Health in writing, either as part of
                    Bonsai Health’s standard ordering process or such other manner communicated to You by Bonsai Health,
                    that You are either a HIPAA covered entity or business associate.
                  </strong>{" "}
                  You acknowledge and agree that, between You and Bonsai Health, it is Your responsibility to inform
                  Bonsai Health of its HIPAA status to ensure the Parties’ compliance with HIPAA to the extent
                  applicable. As such, You represent and warrant that You will inform Bonsai Health in writing to the
                  extent You are a “covered entity” or “business associate” under HIPAA. You further acknowledge and
                  agree that it is Your ongoing responsibility during the Term of this Agreement to inform Bonsai Health
                  in writing to the extent You become subject to HIPAA or another change affecting HIPAA’s applicability
                  to You or Your Data. To the extent that You are a HIPAA covered entity or business associate and Your
                  Data includes any protected health information subject to HIPAA, then: (i) You and Bonsai Health agree
                  that the BAA available{" "}
                  <a href="/business-associate-agreement" target="_blank" rel="noreferrer">
                    here
                  </a>{" "}
                  shall be incorporated herein and will govern the privacy and security of the protected health
                  information that Bonsai Health accesses or receives on Your behalf, and (ii) the Parties agree to
                  comply with their respective obligations under the BAA.
                </li>
              </Typography>
            </li>
            <li>
              <span>6.</span>
              <strong>INTELLECTUAL PROPERTY RIGHTS.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>6.1</span>
                  <strong>Services.</strong> This Agreement does not grant to You any ownership interest in the Services
                  (excluding Your Data). The Services (excluding Your Data) is proprietary to Bonsai Health and Bonsai
                  Health and its licensors have and retain all right, title, and interest, including all Intellectual
                  Property Rights therein and all improvements, enhancements, and modifications thereto. You acknowledge
                  that any trademarks, trade names, logos, service marks, or symbols adopted by Bonsai Health to
                  identify the Services belong to Bonsai Health and/or its licensors, and that You have no rights
                  therein. Except as expressly set forth herein, no express or implied license or right of any kind is
                  granted to You regarding the Services, including any right to obtain possession of any source code,
                  data, or other technical material relating to the Services. All rights not expressly granted to You
                  are reserved by Bonsai Health.
                </li>
                <li>
                  <span>6.2</span>
                  <strong>Ownership of Your Data and Your Confidential Information.</strong> Your Data and Your
                  Confidential Information, and all worldwide Intellectual Property Rights therein, are exclusive
                  property to You. All rights in and to Your Data and Your Confidential Information not expressly
                  granted to Bonsai Health in this Agreement are reserved by You. You grant Bonsai Health a
                  non-exclusive, perpetual, worldwide, royalty-free, and fully paid license to store, process, and use
                  Your Data as necessary for purposes of (i) providing and improving the Services and Bonsai Health’s
                  related products and services; and (ii) for any lawful business purpose.
                </li>
                <li>
                  <span>6.3</span>
                  <strong>Feedback.</strong> You hereby grant Bonsai Health a perpetual, irrevocable, royalty-free, and
                  fully paid right to use and otherwise exploit in any manner any suggestions, ideas, enhancement
                  requests, feedback, recommendations, or other information provided by You related to the Services,
                  including for the purpose of improving and enhancing the Platform; provided that You are not
                  referenced in such use.
                </li>
                <li>
                  <span>6.4</span>
                  <strong>Derived Data.</strong> You hereby grant Bonsai Health a non-exclusive, worldwide,
                  royalty-free, and fully paid license to derive de-identified, aggregated, and/or anonymized data from
                  Your Data (collectively, “<strong>Derived Data</strong>”). You agree that You have no rights or other
                  interests in the Derived Data and that Bonsai Health may use such Derived Data for any lawful business
                  purpose.
                </li>
                <li>
                  <span>6.5</span>
                  <strong>Performance Data.</strong> Bonsai Health may generate Performance Data (for example, to
                  operate, improve, analyze, and support the Services, for benchmarking and reporting purposes and for
                  Bonsai Health’s other lawful business purposes) as part of the Services.
                </li>
              </Typography>
            </li>
            <li>
              <span>7.</span>
              <strong>WARRANTIES; DISCLAIMERS.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>7.1</span>
                  <strong>Third-Party Services.</strong> The Services may contain data, links, or connections to or from
                  third-party websites, products, or services that are not owned or controlled by Bonsai Health and any
                  other third-party services You select as data sources (collectively “
                  <strong>Third-Party Services</strong>”). When You access or use Third-Party Services, or data provided
                  thereby You accept that there are risks in doing so, and that Bonsai Health is not responsible for
                  such risks, or the reliability thereof. Bonsai Health has no control over, and assumes no
                  responsibility for, the information, accuracy, privacy policies, services, or practices of or opinions
                  expressed in any Third-Party Services.
                </li>
                <li>
                  <span>7.2</span>
                  <strong>Your Representations and Warranties.</strong> You represent and warrant that You have provided
                  all appropriate notices and obtained all necessary consents to provide or otherwise make available
                  Your Data to Bonsai Health, including any patient or insured party data that You submit to the
                  Platform.
                </li>
                <li>
                  <span>7.3</span>
                  <strong>DISCLAIMERS.</strong>
                  <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                    <li>
                      <span>(a)</span>
                      <strong>NOT MEDICAL SERVICES.</strong> Bonsai Health does not practice medicine or any other
                      profession, provide medical or other professional clinical advice, make diagnostic, treatment,
                      coding, billing, or other clinical decisions, judgments, or recommendations. The information that
                      is provided to You through the Services is dependent upon Your Data. Bonsai Health is not and will
                      not be responsible for, Your Data or any other user data uploaded or transmitted, or not uploaded
                      or transmitted, through the Services. Further, You agree that You are solely responsible for Your
                      interactions with Your end customer. YOU ACKNOWLEDGE AND AGREE THAT (I) THE SERVICES DO NOT
                      REPLACE MEDICAL CONSULTATION, EXAMINATION, AND RECORD REVIEW; AND (II) THE PROFESSIONAL DUTY TO
                      ANY OF YOUR END CUSTOMERS IN THE PROVISION OF HEALTHCARE SERVICES AND COMPLIANCE WITH THE
                      APPLICABLE STANDARD OF CARE IN DELIVERING SUCH HEALTHCARE SERVICES LIES SOLELY WITH YOU, AS A
                      HEALTHCARE PROFESSIONAL PROVIDING SUCH PATIENT CARE SERVICES AND NOT WITH BONSAI HEALTH. YOU WILL
                      BE RESPONSIBLE FOR ALL CODING, BILLING, PAYMENT, AND REIMBURSEMENT DECISIONS.
                    </li>
                    <li>
                      <span>(b)</span>
                      <strong>GENERAL.</strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE
                      PROVIDED BY BONSAI HEALTH “AS IS” AND “AS AVAILABLE,” AND BONSAI HEALTH AND ITS LICENSORS MAKE NO
                      REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, ORAL, STATUTORY, EXPRESS, IMPLIED, BY
                      COURSE OF COMMUNICATION OR DEALING, OR OTHERWISE. BONSAI HEALTH AND ITS LICENSORS SPECIFICALLY
                      DISCLAIM ANY AND ALL OTHER WARRANTIES, INCLUDING WITH RESPECT TO TITLE, MERCHANTABILITY,
                      NON-INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE OF THE SERVICES FURNISHED UNDER THIS
                      AGREEMENT. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, BONSAI HEALTH DOES NOT WARRANT THAT
                      THE SERVICES ARE ERROR-FREE, ACCURATE OR THAT THE SERVICES WILL OPERATE WITHOUT INTERRUPTION, AND
                      BONSAI HEALTH GRANTS NO WARRANTY REGARDING YOUR USE OF THE SERVICES. THE SERVICES MAY BE SUBJECT
                      TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
                      COMMUNICATIONS. BONSAI HEALTH IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER
                      DAMAGES RESULTING FROM SUCH PROBLEMS.
                    </li>
                  </Typography>
                </li>
              </Typography>
            </li>
            <li>
              <span>8.</span>
              <strong>INDEMNIFICATION.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>8.1</span>
                  <strong>By You.</strong> You will defend at Your expense any suit brought against Bonsai Health, and
                  will pay any settlement You make or approve, or any damages finally awarded in such suit, insofar as
                  such suit is based on a claim arising out of or relating to a claim arising out of (a) Your Data,
                  including any breach or alleged breach of Section 7.2, (b) Your use or misuse of the Services,
                  including any use of the Services in violation of applicable laws or regulations; (c) Your failure to
                  inform Bonsai Health in writing that You are a HIPAA covered entity or business associate pursuant to
                  Section 5.5 or (d) the care or services You provide Your end customer or other third parties.
                </li>
                <li>
                  <span>8.2</span>
                  <strong>By Bonsai Health.</strong> Bonsai Health will defend at its expense any suit brought against
                  You, and will pay any settlement Bonsai Health makes or approves, or any damages finally awarded in
                  such suit, insofar as such suit is based on a claim arising out of or relating to a third-party claim
                  alleging that the Platform or API(s) as provided to You and used in accordance with this Agreement
                  infringes a third party’s Intellectual Property Rights, provided that Bonsai Health shall have no
                  liability to the extent that the alleged infringement arises from: (a) alterations or modifications
                  made to the Platform or API(s) made by You or any third party; (b) Your Data, including any breach or
                  alleged breath of Section 2.4; (c) content generated or provided by Third-Party Services; (d) use of
                  the Platform or API(s) in combination with products or content not supplied by the Services where the
                  claim would not have arisen in the absence of such combination; or (e) use of the Platform or API(s)
                  in violation of the Agreement or applicable laws. The foregoing represents Bonsai Health’s sole
                  obligation, and Your sole remedy, regarding an allegation that the Platform or API(s) infringes a
                  third party’s Intellectual Property Rights.
                </li>
                <li>
                  <span>8.3</span>
                  <strong>Indemnification Procedure.</strong> The indemnifying Party’s obligations as set forth above
                  are expressly conditioned upon each of the foregoing: (a) the indemnified Party will promptly notify
                  the indemnifying Party in writing of any threatened or actual claim or suit (provided, however, that
                  the indemnified Party’s failure to do so shall not relieve the indemnifying Party of its
                  indemnification obligations hereunder except to the extent that the indemnifying Party has been
                  adversely affected by the failure to receive such prompt notice); (b) the indemnifying Party will have
                  sole control of the defense or settlement of any claim or suit; and (c) the indemnified Party will
                  cooperate with the indemnifying Party to facilitate the settlement or defense of any claim or suit.
                </li>
              </Typography>
            </li>
            <li>
              <span>9.</span>
              <strong>LIMITATION OF LIABILITY.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>9.1</span>
                  <strong>Types of Damages.</strong> NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY NOR TO ANY THIRD
                  PARTIES FOR LOST PROFITS OR LOST DATA OR FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, RELIANCE, OR
                  PUNITIVE LOSSES OR DAMAGES HOWSOEVER ARISING UNDER THIS AGREEMENT OR IN CONNECTION WITH THE SERVICES
                  WHETHER UNDER CONTRACT, TORT OR OTHERWISE, WHETHER FORESEEABLE OR NOT AND REGARDLESS OF WHETHER SUCH
                  PARTY HAS BEEN ADVISED OF THE POSSIBILITY THAT SUCH DAMAGES MAY ARISE, OCCUR OR RESULT. IN NO EVENT
                  SHALL BONSAI HEALTH BE LIABLE FOR PROCUREMENT COSTS OF SUBSTITUTE PRODUCTS OR SERVICES.
                </li>
                <li>
                  <span>9.2</span>
                  <strong>Amount of Damages.</strong> IN NO EVENT WILL EITHER PARTY’S AGGREGATE CUMULATIVE LIABILITY
                  UNDER THIS AGREEMENT EXCEED THE AMOUNT OF FEES PAID OR PAYABLE BY YOU TO BONSAI HEALTH UNDER THE
                  APPLICABLE ORDER FORM IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE
                  CLAIM.
                </li>
                <li>
                  <span>9.3</span>
                  <strong>Exclusions.</strong> THESE LIMITATIONS OF LIABILITY IN THIS SECTION 9 DO NOT APPLY TO: (A)
                  YOUR BREACH OF SECTION 2 OR SECTION 5.5; (B) EITHER PARTY’S INDEMNIFICATION OBLIGATIONS; (C) YOUR
                  PAYMENT OBLIGATIONS; OR (C) CLAIMS ARISING FROM EITHER PARTY’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
                  THESE LIMITATIONS OF LIABILITY WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY
                  LIMITED REMEDY.
                </li>
              </Typography>
            </li>
            <li>
              <span>10.</span>
              <strong>GENERAL PROVISIONS.</strong>
              <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                <li>
                  <span>10.1</span>
                  <strong>Relationship Between the Parties.</strong> Bonsai Health is an independent contractor; nothing
                  in this Agreement will be construed to create a partnership, joint venture, or agency relationship
                  between the Parties. Neither Party will have, nor represent to any third party that it has, any
                  authority to act on behalf of the other Party. Each Party will be solely responsible for payment of
                  all compensation owed to its employees, as well as employment related taxes.
                </li>
                <li>
                  <span>10.2</span>
                  <strong>Injunctive Relief.</strong> You acknowledge that the Services contain valuable Intellectual
                  Property Rights and proprietary information of Bonsai Health, that any actual or threatened breach of
                  Sections 2 or 5 will constitute immediate, irreparable harm to Bonsai Health for which monetary
                  damages would be an inadequate remedy, and that injunctive relief is an appropriate remedy for such
                  breach. If You continue to use the Services after its right to do so has terminated or expired, Bonsai
                  Health will be entitled to immediate injunctive relief without the requirement of posting bond.
                </li>
                <li>
                  <span>10.3</span>
                  <strong>Assignment.</strong> Neither Party may assign or transfer its rights or obligations under this
                  Agreement without the prior written consent of the other Party, and any assignment or transfer of the
                  foregoing shall be null and void, provided, however that either Party shall have the right to assign
                  the Agreement, without the prior written consent of the other Party, to the successor entity in the
                  event of a merger, corporate reorganization, or a sale of all or substantially all of such Party’s
                  assets. This Agreement shall be binding upon the Parties and their respective successors and permitted
                  assigns.
                </li>
                <li>
                  <span>10.4</span>
                  <strong>Publicity.</strong> Bonsai Health may use Your name and logo in its customer list (including
                  on Bonsai Health’s Website, social media and in sales and marketing materials) in the same manner in
                  which it uses the names of its other customers, provided that at all times Bonsai Health shall use
                  Your name and logo in accordance with Your applicable branding guidelines and Bonsai Health may not
                  use Your name in any other way without Your prior written consent (with email consent deemed
                  sufficient).
                </li>
                <li>
                  <span>10.5</span>
                  <strong>Notices.</strong> All notices required or permitted under this Agreement must be delivered in
                  writing, if to Bonsai Health, to the address set forth below, and if to You, by emailing the Point of
                  Contact email address listed on the Order Form. Notice will be deemed effective: (i) at the time of
                  delivery when delivered personally; (ii) one (1) day after delivery if by overnight courier service or
                  three (3) days after deposit in mail; or (iii) upon confirmed dispatch if by email. Each Party may
                  change its email address and/or address for receipt of notice by giving notice of such change to the
                  other Party.
                  <Typography component="ul" className="emptyDecimal" variant="bodyTimes" sx={{ mb: 2 }}>
                    <li>
                      <strong>Notices for Bonsai Health:</strong>
                      <br />
                      <br />
                      Bonsai Health, Inc.
                      <br />
                      12100 Wilshire Blvd, Suite 800
                      <br />
                      Los Angeles, California 90025
                      <br />
                      Attn: Co-Chief Executive Officers
                    </li>
                  </Typography>
                </li>
                <li>
                  <span>10.6</span>
                  <strong>Governing Law; Dispute Resolution.</strong>  The Agreement is governed by the laws of the State of California, without regard to its conflicts of laws or provisions and this Agreement shall not be governed or affected by any version of the Uniform Computer Information Transactions Act enacted in any jurisdiction.  The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.  The prevailing Party in any action to enforce this Agreement shall be entitled to recover attorneys’ fees, court costs, and other collection expenses.  In the event of any dispute arising under or relating to this Agreement, the parties shall first attempt to resolve such dispute amicably through informal dispute resolution, which shall commence by one party sending a notice of dispute to the other party. Following such notice, representatives from both parties with authority to resolve the dispute shall meet and confer to negotiate a resolution. Any dispute that is not resolved within thirty (30) days after the initial meeting or forty-five (45) days after the initial notice shall be determined and settled by confidential arbitration, conducted in English, held in Los Angeles County, California, administered by the American Arbitration Association (“<strong>AAA</strong>”) before a sole arbitrator in accordance with the then-current AAA Commercial Arbitration Rules. The award rendered by the arbitrator shall be final and binding on the parties thereto, and judgment thereon may be entered in any court of competent jurisdiction. Nothing in this section shall prevent either party from applying to a court of competent jurisdiction for equitable or injunctive relief.
                </li>
                <li>
                  <span>10.7</span>
                  <strong>Waivers; Severability.</strong>  Any waivers shall be effective only if made by writing signed by representatives authorized to bind the Parties.  Any waiver or failure to enforce any provision of this Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.  If any provision of this Agreement is unenforceable, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                </li>
                <li>
                  <span>10.8</span>
                  <strong>Construction.</strong>  The headings of sections of this Agreement are for convenience and are not to be used in interpreting this Agreement.  As used in this Agreement, the word “including” means “including but not limited to.” Words used in this Agreement, regardless of the number or gender specifically used, shall be deemed and construed to include any other number, singular or plural, and any other gender, masculine, feminine or neuter, as the context shall require.
                </li>
                <li>
                  <span>10.9</span>
                  <strong>Force Majeure.</strong>  Any delay in the performance of any duties or obligations of either Party (except for the obligation to pay Fees owed) will not be considered a breach of this Agreement if such delay is caused by a labor dispute, shortage of materials, war, fire, earthquake, typhoon, flood, natural disasters, governmental action, pandemic/epidemic, cyber terrorism, cloud-service provider outages any other event beyond the control of such Party, provided that such Party uses reasonable efforts, under the circumstances, to notify the other Party of the circumstances causing the delay and to resume performance as soon as possible.
                </li>
                <li>
                  <span>10.10</span>
                  <strong>Entire Agreement; Amendment.</strong> This Agreement, any Exhibits, BAA (if applicable), and  Order Forms constitutes the complete agreement between the Parties and supersedes all previous and contemporaneous agreements, proposals, or representations, written or oral, concerning the subject matter of this Agreement.  To the extent that a conflict arises between the terms and conditions of an Order Form and the terms of this Agreement, the terms and conditions of this Agreement will govern, except to the extent that the Order Form, as applicable, expressly states that it supersedes specific language in the Agreement.  It is expressly agreed that the terms and conditions of this Agreement and any Order Form supersede the terms any purchase order from You.  Neither this Agreement nor an Order Form may be modified or amended except in writing signed by a duly authorized representative of each Party; no other act, document, usage, or custom will be deemed to amend or modify this Agreement or an Order Form.
                </li>
              </Typography>
            </li>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsOfUse;
