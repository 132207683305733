import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import TopLogo from "../components/TopLogo";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Bonsai</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container sx={{ p: 3 }}>
        <TopLogo />
        <Box pt={3}>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Privacy Policy
          </Typography>
          <Typography sx={{ mb: 1 }}>Effective as of May 26, 2024</Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            This Privacy Policy describes how Bonsai Health, Inc. ("<strong>Bonsai Health</strong>", "
            <strong>we</strong>
            ", “<strong>us</strong>” or "<strong>our</strong>") handles personal information that we collect through our
            digital properties that link to this Privacy Policy, including our website (collectively, the “
            <strong>Service</strong>”), as well as through social media, our marketing activities, and other activities
            described in this Privacy Policy.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            Bonsai Health provides a data analytics and revenue automation platform to healthcare practices. This
            Privacy Policy does not apply to information that we process on behalf of our customers while providing the
            Bonsai Health platform and associated services to them. Our use of information that we process on behalf of
            our enterprise customers may be governed by our agreements with such customers. If you have questions
            regarding the personal information that we process on behalf of an enterprise customer, please direct your
            questions to that enterprise customer.
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            Our websites, products and services are designed for enterprise customers and their representatives. We do
            not offer products or services for use by individuals for their personal, familial or household purposes.
            Accordingly, we treat all personal information we collect as pertaining to individuals in their capacities
            as representatives of the relevant enterprise and not their individual capacities.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Index
          </Typography>
          <Typography component="ul" sx={{ fontWeight: "bold", mb: 4 }}>
            <li>Personal information we collect</li>
            <li>How we use your personal information</li>
            <li>How we share your personal information</li>
            <li>Your choices</li>
            <li>Other sites and services </li>
            <li>Security</li>
            <li>International data transfer</li>
            <li>Children</li>
            <li>Changes to the Privacy Policy</li>
            <li>How to contact us</li>
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Personal information we collect
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Information you provide to us or that we generate about you.</strong> Personal information you may
            provide to us through the Service or otherwise includes:
          </Typography>
          <Typography component="ul" sx={{ mb: 4 }}>
            <li>
              <strong>Contact data</strong>, such as your first and last name, salutation, email address, mailing
              address, professional title, and phone number.
            </li>
            <li>
              <strong>Demographic data</strong>, such as your city, state, country of residence, and postal code.
            </li>
            <li>
              <strong>Communications data</strong> based on our exchanges with you, including when you contact us
              through the Service, social media, or otherwise.
            </li>
            <li>
              <strong>Marketing data</strong>, such as your preferences for receiving our marketing communications and
              details about your engagement with them.
            </li>
            <li>
              <strong>Profile data</strong>, such as the username and password that you may set to establish an online
              account on the Service, redemption code, biographical details, photograph or picture, and any other
              information that you add to your account profile.
            </li>
            <li>
              <strong>Professional data</strong> such as your employer’s information.
            </li>
            <li>
              <strong>Transactional data</strong>, such as information relating to or needed to complete your orders on
              or through the Service, including order numbers and transaction history.
            </li>
            <li>
              <strong>Payment data</strong> needed to complete transactions, including payment card information.
            </li>
            <li>
              <strong>End customer data</strong>, such as information relating to our enterprise customers’ clients’
              personal information including dates of service, appointment history with associated information (such as
              service fees, appointment lengths, outreach efforts), and other metric-oriented information.
            </li>
            <li>
              <strong>User-generated content data</strong>, such as content or information that you generate, transmit
              or otherwise make available on the Service, as well as associated metadata. Metadata includes information
              on how, when, where and by whom a piece of content was collected and how that content has been formatted
              or edited. Metadata also includes information that users can add or can have added to their content, such
              as keywords, geographical or location information, and other similar data.
            </li>
            <li>
              <strong>Other data</strong> not specifically listed here, which we will use as described in this Privacy
              Policy or as otherwise disclosed at the time of collection.
            </li>
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Third-party sources.</strong> We may combine personal information we receive from you with personal
            information we obtain from other sources, such as:
          </Typography>
          <Typography component="ul" sx={{ mb: 4 }}>
            <li>
              <strong>Public sources</strong>, such as government agencies, public records, social media platforms, and
              other publicly available sources.
            </li>
            <li>
              <strong>Data providers</strong>, such as data licensors.
            </li>
            <li>
              <strong>Marketing partners</strong>, such as joint marketing partners and event co-sponsors.
            </li>
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Automatic data collection.</strong> We, our service providers, and our business partners may
            automatically log information about you, your computer or mobile device, and your interaction over time with
            the Service, our communications and other online services, such as:
          </Typography>
          <Typography component="ul" sx={{ mb: 4 }}>
            <li>
              <strong>Device data</strong>, such as your computer or mobile device’s operating system type and version,
              manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g.,
              phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes),
              language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 3G), and general
              location information such as city, state or general geographic area.
            </li>
            <li>
              <strong>Online activity data</strong>, such as pages or screens you viewed, how long you spent on a page
              or screen, the website you visited before browsing to the Service, navigation paths between pages or
              screens, information about your activity on a page or screen, access times and duration of access, and
              whether you have opened our emails or clicked links within them.
            </li>
            <li>
              <strong>Communication interaction data</strong> such as your interactions with our email, text or other
              communications (e.g., whether you open and/or forward emails) – we may do this through use of pixel tags
              (which are also known as clear GIFs), which may be embedded invisibly in our emails.{" "}
            </li>
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Cookies and similar technologies.</strong> Some of the automatic collection described above is
            facilitated by the following technologies:
          </Typography>
          <Typography component="ul" sx={{ mb: 4 }}>
            <li>
              <strong>Cookies</strong>, which are small text files that websites store on user devices and that allow
              web servers to record users’ web browsing activities and remember their submissions, preferences, and
              login status as they navigate a site. Cookies used on our sites include both “session cookies” that are
              deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we place
              and “third party” cookies that our third-party business partners and service providers place.{" "}
            </li>
            <li>
              <strong>Local storage technologies</strong>, like HTML5, that provide cookie-equivalent functionality but
              can store larger amounts of data on your device outside of your browser in connection with specific
              applications.
            </li>
            <li>
              <strong>Web beacons</strong>, also known as pixel tags or clear GIFs, which are used to demonstrate that a
              webpage or email was accessed or opened, or that certain content was viewed or clicked.{" "}
            </li>
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            How we use your personal information
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            We may use your personal information for the following purposes or as otherwise described at the time of
            collection:
            <br />
            <br />
            <strong>Service delivery and business operations.</strong> We may use your personal information to:
          </Typography>
          <Typography component="ul" sx={{ mb: 2 }}>
            <li>provide, operate and improve the Service and our business;</li>
            <li>
              communicate with you about the Service, including by sending announcements, updates, security alerts, and
              support and administrative messages;
            </li>
            <li>communicate with you about events in which you participate;</li>
            <li>
              understand your needs and interests, and personalize your experience with the Service and our
              communications; and
            </li>
            <li>provide support for the Service, and respond to your requests, questions and feedback.</li>
          </Typography>
          <Typography component="p" sx={{ mb: 2 }}>
            <strong>Research and development.</strong> We may use your personal information for research and development
            purposes, including to analyze and improve the Service and our business. As part of these activities, we may
            create aggregated, de-identified or anonymized data from personal information we collect. We make personal
            information into anonymous data by removing information that makes the data personally identifiable to you.
            We may use aggregated, de-identified and/or anonymized data and share it with third parties for our lawful
            business purposes, including to analyze and improve the Service and promote our business.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Marketing and advertising.</strong> We, our service providers and our third-party partners may
            collect and use your personal information for marketing or advertising purposes:
          </Typography>
          <Typography component="ul" sx={{ mb: 2 }}>
            <li>
              <strong>Direct marketing.</strong> We may send you direct marketing communications. You may opt-out of our
              marketing communications as described in the <u>Opt-out of marketing</u> section below.
            </li>
            <li>
              <strong>Interest-based advertising.</strong> Our third-party advertising partners may use cookies and
              similar technologies to collect information about your interaction (including the data described in the
              automatic data collection section above) with the Service, our communications and other online services
              over time, and use that information to serve online ads that they think will interest you. This is called
              interest-based advertising. We may also share information about our users with these companies to
              facilitate interest-based advertising to those or similar users on other online platforms
            </li>
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Compliance and protection.</strong> We may use your personal information to:
          </Typography>
          <Typography component="ul" sx={{ mb: 2 }}>
            <li>
              comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or
              requests from government authorities;
            </li>
            <li>
              protect our, your or others’ rights, privacy, safety or property (including by making and defending legal
              claims);
            </li>
            <li>
              audit our internal processes for compliance with legal and contractual requirements or our internal
              policies;
            </li>
            <li>enforce the terms and conditions that govern the Service; and</li>
            <li>
              prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity,
              including cyberattacks and identity theft.
            </li>
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>With your consent.</strong> In some cases, we may specifically ask for your consent to collect, use
            or share your personal information, such as when required by law.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Cookies and similar technologies.</strong> In addition to the other uses included in this section,
            we may use the <u>Cookies and similar technologies</u> described above for the following purposes:
          </Typography>
          <Typography component="ul" sx={{ mb: 4 }}>
            <li>
              <strong>Technical operation.</strong> To allow the technical operation of the Service.
            </li>
            <li>
              <strong>Functionality.</strong> To enhance the performance and functionality of our services.
            </li>
            <li>
              <strong>Advertising.</strong> To help our third-party advertising partners collect information about how
              you use the Service and other online services over time, which they use to show you ads on other online
              services they believe will interest you and measure how the ads perform.
            </li>
            <li>
              <strong>Analytics.</strong> To help us understand user activity on the Service, including which pages are
              most and least visited and how visitors move around the Service, as well as user interactions with our
              emails.{" "}
            </li>
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            How we share your personal information
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            We may share your personal information with the following parties and as otherwise described in this Privacy
            Policy or at the time of collection.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Affiliates.</strong> Our corporate parent, subsidiaries, and affiliates, for purposes consistent
            with this Privacy Policy.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Service providers.</strong> Third parties that provide services on our behalf or help us operate the
            Service or our business (such as hosting, information technology, customer support, email delivery, consumer
            research, marketing, and website analytics).{" "}
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Payment processors.</strong> Any payment card information you use to make a purchase on the Service
            is collected and processed directly by our payment processors [such as Stripe. Stripe may use your payment
            data in accordance with its privacy policy, https://stripe.com/privacy.]
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Advertising partners.</strong> Third-party advertising companies for the interest-based advertising
            purposes described above. We do not necessarily control how these advertising partners may use your personal
            information.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Third parties designated by you.</strong> We may share your personal data with third parties where
            you have instructed us or provided your consent to do so. We do not necessarily control how these third
            parties may use your personal information.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Business and marketing partners.</strong> Third parties with whom we co-sponsor events, with whom we
            jointly offer products or services, or whose products or services may be of interest to you. We do not
            necessarily control how these business and marketing partners may use your personal information.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Financial partners.</strong> Third parties to whom we disclose End Customer Data or other personal
            information such as pharmaceutical companies, consulting companies and others. We do not necessarily control
            how these financial partners may use such personal information that we disclose to them.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Professional advisors.</strong> Professional advisors, such as lawyers, auditors, bankers and
            insurers, where necessary in the course of the professional services that they render to us.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Authorities and others.</strong> Law enforcement, government authorities, and private parties, as we
            believe in good faith to be necessary or appropriate for the <u>compliance and protection purposes</u>{" "}
            described above.{" "}
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Business transferees.</strong> We may disclose personal information in the context of actual or
            prospective business transactions (e.g., investments in or financings of Bonsai Health, public stock
            offerings, or the sale, transfer or merger of all or part of our business, assets or shares), for example,
            we may need to share certain personal information with prospective counterparties and their advisers. We may
            also disclose your personal information to an acquirer, successor, or assignee of Bonsai Health as part of
            any merger, acquisition, sale of assets, or similar transaction, and/or in the event of an insolvency,
            bankruptcy, or receivership in which personal information is transferred to one or more third parties as one
            of our business assets.
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            <strong>Other users and the public.</strong> Your profile and other user-generated content data (except for
            messages) may be visible to other users of the Service and the public. For example, other users of the
            Service or the public may have access to your information if you chose to make your profile or other
            personal information available to them through the Service, such as when you provide comments, reviews,
            survey responses, or share other content. This information can be seen, collected and used by others,
            including being cached, copied, screen captured or stored elsewhere by others (e.g., search engines), and we
            are not responsible for any such use of this information.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Your choices
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            In this section, we describe the choices available to you.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Opt-out of marketing communications.</strong> You may opt-out of marketing-related emails by
            following the opt-out or unsubscribe instructions at the bottom of the email, or by <u>contacting us</u>.
            Please note that if you choose to opt-out of marketing-related emails, you may continue to receive
            service-related and other non-marketing emails. If you receive marketing-related text/SMS messages from us,
            you may opt out of receiving such messages from us by replying STOP in response to any such message.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Declining to provide information.</strong> We need to collect personal information to provide
            certain services. If you do not provide the information we identify as required or mandatory, we may not be
            able to provide those services.
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            <strong>Advertising choices.</strong> You may be able to limit use of your information for interest-based
            advertising through the following settings/options/tools:
          </Typography>
          <Typography component="ul" sx={{ mb: 2 }}>
            <li>
              <strong>Browser settings.</strong> Changing your internet web browser settings to block third-party
              cookies.
            </li>
            <li>
              <strong>Privacy browsers/plug-ins.</strong> Using privacy browsers and/or ad-blocking browser plug-ins
              that let you block tracking technologies.{" "}
            </li>
            <li>
              <strong>Platform settings.</strong> Google and Facebook offer opt-out features that let you opt-out of use
              of your information for interest-based advertising. You may be able to exercise that option at the
              following websites:
              <Typography component="ul">
                <li>
                  Google:{" "}
                  <a href="https://adssettings.google.com/" target="_blank" rel="noreferrer">
                    https://adssettings.google.com/
                  </a>
                </li>
                <li>
                  Facebook:{" "}
                  <a href="https://www.facebook.com/about/ads " target="_blank" rel="noreferrer">
                    https://www.facebook.com/about/ads
                  </a>
                </li>
              </Typography>
            </li>
            <li>
              <strong>Ad industry tools.</strong> Opting out of interest-based ads from companies that participate in
              the following industry opt-out programs:
              <Typography component="ul">
                <li>
                  Network Advertising Initiative:{" "}
                  <a href="https://thenai.org/opt-out/" target="_blank" rel="noreferrer">
                    https://thenai.org/opt-out/
                  </a>{" "}
                </li>
                <li>
                  Digital Advertising Alliance:{" "}
                  <a href="https://optout.aboutads.info/?c=2&lang=EN " target="_blank" rel="noreferrer">
                    https://optout.aboutads.info/?c=2&lang=EN
                  </a>{" "}
                </li>
                <li>
                  AppChoices mobile app, available at{" "}
                  <a href="https://www.youradchoices.com/appchoices" target="_blank" rel="noreferrer">
                    https://www.youradchoices.com/appchoices
                  </a>
                  , which will allow you to opt-out of interest-based ads in mobile apps served by participating members
                  of the Digital Advertising Alliance.
                </li>
              </Typography>
            </li>
            <li>
              <strong>Mobile settings.</strong> Using your mobile device settings to limit use of the advertising ID
              associated with your mobile device for interest-based advertising purposes.
            </li>
          </Typography>
          <Typography component="p" sx={{ mb: 1 }}>
            You will need to apply these opt-out settings on each device and browser from which you wish to limit the
            use of your information for interest-based advertising purposes. We cannot offer any assurances as to
            whether the companies we work with participate in the opt-out programs described above.
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            <strong>Declining to provide information.</strong> We need to collect personal information to provide
            certain services. If you do not provide the information we identify as required or mandatory, we may not be
            able to provide those services.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Other sites and services
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            The Service may contain links to websites, mobile applications, and other online services operated by third
            parties. In addition, our content may be integrated into web pages or other online services that are not
            associated with us. These links and integrations are not an endorsement of, or representation that we are
            affiliated with, any third party. We do not control websites, mobile applications or online services
            operated by third parties, and we are not responsible for their actions. We encourage you to read the
            privacy policies of the other websites, mobile applications and online services you use.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Security
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            We employ a number of technical, organizational and physical safeguards designed to protect the personal
            information we collect. However, security risk is inherent in all internet and information technologies, and
            we cannot guarantee the security of your personal information.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            International data transfer
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            We are headquartered in the United States and may use service providers that operate in other countries.
            Your personal information may be transferred to the United States or other locations where privacy laws may
            not be as protective as those in your state, province, or country.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Children
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            The Service is not intended for use by anyone under 18 years of age. If you are a parent or guardian of a
            child from whom you believe we have collected personal information in a manner prohibited by law, please
            contact us. If we learn that we have collected personal information through the Service from a child without
            the consent of the child’s parent or guardian as required by law, we will comply with applicable legal
            requirements to delete the information.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Changes to this Privacy Policy
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy
            Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service or
            other appropriate means. Any modifications to this Privacy Policy will be effective upon our posting the
            modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service
            after the effective date of any modified Privacy Policy indicates your acknowledging receipt of the modified
            Privacy Policy.
          </Typography>
          <Typography variant="h2" sx={{ mb: 1 }}>
            How to contact us
          </Typography>
          <Typography component="ul" sx={{ mb: 4 }}>
            <li>
              Email: <a href="mailto:privacy@bonsaihealth.com">privacy@bonsaihealth.com</a>
            </li>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
