import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import imgLogo from "../images/logo.svg";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <Container sx={{ p: 3 }}>
      <motion.div
        initial={{ left: "-20px", position: "relative", opacity: 0 }}
        animate={{ left: 0, opacity: 1 }}
        transition={{ duration: 0.25, ease: "easeInOut" }}
      >
        <Link to="/">
          <Box component="img" src={imgLogo} alt="logo" sx={{ position: "relative", height: { xs: 24, md: 32 } }} />
        </Link>
      </motion.div>
      <Box pt={3}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          404
        </Typography>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Uh oh...
        </Typography>
        <Typography component="p">We can't seem to find the page you're looking for.</Typography>
      </Box>
    </Container>
  );
};

export default Error;
